<template>
  <div>
    <li class="LiMenor">
      <a class="LinkLogin" id="BtnOpenLogin" @click="OpenLoginMenu()">
        {{ $t('public.headMenu.signIn.signIn') }}
      </a>
    </li>
    <li class="LiMenor">
      <a class="LinkLogin" @click="requestAccess()">
        {{ $t('public.headMenu.signIn.signUp') }}
      </a>
    </li>
    <div class="DivLogin" id="DivLogin">
      <div class="DivBtnFecharLogin">
        <a
          class="BtnFecharLogin"
          id="BtnCloseLogin"
          @click="CloseLoginMenu()"
          style="background: white !important"
        >
          <img
            src="@/views/public/svg/Menu_Close.svg"
            class="img-fluid"
            alt="LensXpert"
            style="left: 10px; position: absolute; width: 20px"
          />
        </a>
      </div>

      <h3 id="LoginTitle" class="AzulClaro1">
        {{ $t('public.headMenu.signIn.access') }}<br />
        {{ $t('public.headMenu.signIn.toLensxpert') }}<br />
        {{ $t('public.headMenu.signIn.system') }}
      </h3>

      <div id="LoginBody" class="col-12 NoPadding top30">
        <validation-observer ref="loginValidation">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <input
              type="text"
              class="form-control FormLogin"
              placeholder="example@co.com"
              v-model="userEmail"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required"
          >
            <input
              type="password"
              class="form-control FormLogin mt-4"
              placeholder="············"
              v-model="password"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <button class="BtnEntraLogin mt-4" @click="signIn('/admin')">
            {{ $t('public.headMenu.signIn.signIn') }}
          </button>
        </validation-observer>
      </div>

      <div id="LoginFoot" class="col-12 NoPadding top30">
        <a href="javascript:void(0)" @click="OpenForgotPassword()">
          {{ $t('public.headMenu.signIn.forget') }}
        </a>
      </div>

      <div id="ForgotPasswordTitle" class="Hide">
        <h3 class="AzulClaro1">
          {{ $t('public.headMenu.signIn.reset') }}<br />
          {{ $t('public.headMenu.signIn.password') }}
        </h3>
        <br />
        {{ $t('public.headMenu.signIn.resetDescription') }}
      </div>

      <div id="ForgotPasswordBody" class="col-12 NoPadding top30 Hide">
        <validation-observer ref="loginValidation">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <input
              type="text"
              class="form-control FormLogin"
              placeholder="example@co.com"
              v-model="userEmail"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <button class="BtnEntraLogin mt-4" @click="resetPassword()">
            {{ $t('public.headMenu.signIn.sendLink') }}
          </button>
        </validation-observer>
      </div>

      <div id="ForgotPasswordFoot" class="col-12 NoPadding top30 Hide">
        <a href="javascript:void(0)" @click="CloseForgotPassword()">
          {{ $t('public.headMenu.signIn.back') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  OpenLoginMenu,
  CloseLoginMenu,
  OpenForgotPassword,
  CloseForgotPassword,
} from '@/views/public/js/LoginMenu.js'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import router from '@/router'
import useLogin from '@/views/auth/useLogin'
import AuthController from '@/controllers/auth.controller'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      required,
      email,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    resetPassword() {
      new AuthController()
        .resetPassword(this.userEmail)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('toast.resetPassword.success'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch((e) => {
          console.error(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('toast.resetPassword.failure'),
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    OpenLoginMenu,
    CloseLoginMenu,
    OpenForgotPassword,
    CloseForgotPassword,
  },

  setup() {
    const { signIn, loginValidation, userEmail, password, loading } = useLogin()

    const requestAccess = () => {
      router.push({ name: 'request-access' })
    }

    return {
      signIn,
      requestAccess,
      loginValidation,
      userEmail,
      password,
      loading,
    }
  },
}
</script>

<style></style>
