import i18n from '@/libs/i18n'

/**
 * Gets an error message according to its message and status.
 *
 * @param {Object} error the error object containing its status code.
 * @returns {string} a string that represents the error key value.
 */
export const getErrorMessage = (error) => {
  console.log('------')
  console.log(error)
  if (!error) {
    return 'an-error-occurred'
  }

  const status = error.statusCode || error.status || 500

  if (error.message === 'Network Error') {
    return i18n.t('firebase.error.network')
  }

  if (error.code == 'auth/user-not-found') {
    return i18n.t('firebase.error.userInUse')
  }

  if (error.code == 'auth/email-already-in-use') {
    return i18n.t('firebase.error.emailInUse')
  }

  if (error.code == 'auth/invalid-email') {
    return i18n.t('firebase.error.invalidEmail')
  }

  if (error.code == 'auth/operation-not-allowed') {
    return i18n.t('firebase.error.notAllowed')
  }

  if (error.code == 'auth/weak-password') {
    return i18n.t('firebase.error.weakPassword')
  }

  if (error.message === 'Email Already in Use') {
    return i18n.t('firebase.error.emailInUse')
  }

  if (status === 401) {
    if (error.message === 'Invalid email or password') {
      return i18n.t('firebase.error.invalidEmail')
    }

    return i18n.t('firebase.error.notAllowed')
  }

  if (status === 400) {
    return 'bad-request'
  }

  return error.code ?? 'an-error-occurred'
}
