export function OpenMenu() {
    document.getElementById("Porta_Btn_OpenMenu").classList.add("Desce_Porta_Btn_Menu");
    document.getElementById("Btn_OpenMenu").classList.add("Hide_Btn_OpenMenu");
    document.getElementById("Btn_CloseMenu").classList.remove("Hide_Btn_CloseMenu");
    document.getElementById("Porta_Menu").classList.add("Show_Porta_Menu_Small");
    document.getElementById("Porta_Menu").classList.add("Cor_Porta_Menu_Small");
    document.getElementById("Porta_Menu").classList.add('Porta_Menu_Small');
    document.querySelector(".menu").classList.remove("close");
}

export function CloseMenu() {
    document.getElementById("Porta_Btn_OpenMenu").classList.remove("Desce_Porta_Btn_Menu");
    document.getElementById("Btn_OpenMenu").classList.remove("Hide_Btn_OpenMenu");
    document.getElementById("Btn_CloseMenu").classList.add("Hide_Btn_CloseMenu");
    document.getElementById("Porta_Menu").classList.remove('Show_Porta_Menu_Small');
	  document.getElementById("Porta_Menu").classList.remove('Cor_Porta_Menu_Small');
    document.getElementById("Porta_Menu").classList.remove('Porta_Menu_Small');
    document.querySelector(".menu").classList.add("close");
}

