export function PorqueVerdeButton (){
		if (document.getElementById('PorqueVerdeTabelaOnline').classList.contains('PenseVerdeAberto') ) {
			document.getElementById("Resposta").classList.remove("Desce_Resposta");
			document.getElementById("TextoResposta").classList.remove("Show_TextoResposta");
			document.getElementById("PorqueVerdeTabelaOnline").classList.remove("PenseVerdeAberto");
			document.getElementById("PorqueVerdeTabelaOnline").classList.remove("Sobe_Pergunta");
			
			setTimeout(function() {
				document.getElementById('PorqueVerdeTabelaOnline').classList.add('Some_Pergunta1');
			}, 400);
			
			setTimeout(function() {
				document.getElementById('PorqueVerdeTabelaOnline').classList.add('Some_Pergunta2');
				
			}, 600);
		} else {
			document.getElementById("PorqueVerdeTabelaOnline").classList.add("PenseVerdeAberto");
			document.getElementById("PorqueVerdeTabelaOnline").classList.add("Sobe_Pergunta");
			document.getElementById("Resposta").classList.add("Desce_Resposta");
			document.getElementById("TextoResposta").classList.add("Show_TextoResposta");
		}
}