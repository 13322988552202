import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import store from '../../store'

import AuthLoginModel from '../../models/auth-login.model'
import { getErrorMessage } from '../../utils/errors'
import router from '../../router'

export default function () {
  const toast = useToast()

  const loginValidation = ref(null)

  const userEmail = ref('')
  const password = ref('')

  const loading = ref(false)

  /**
   * Shows an error toast on screen.
   *
   * @param {Object} error the error object to be shown.
   */
  const showError = (error) => {
    toast({
      component: ToastificationContent,
      props: {
        title: getErrorMessage(error),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }

  /**
   * Signs in the user using its email and password.
   *
   * @param {string} navigateTo the route path to navigate to after signig in.
   */
  const signIn = async (navigateTo) => {
    loading.value = true

    const success = await loginValidation.value.validate()

    if (!success) {
      return
    }

    const result = await store.dispatch(
      'auth/signIn',
      new AuthLoginModel({ email: userEmail.value, password: password.value }),
    )

    const error = result ? result.error : { statusCode: 500 }

    if (error) {
      loading.value = false
      showError(error)
      return
    }

    store.dispatch('auth/setUserId', result.user.id)
    store.dispatch('auth/setUser', result.user)
    store.dispatch(
      'auth/loadAdminDocData',
      result.user.adminDocRef || result.user.sellerDocRef,
    )

    loading.value = false

    toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('toast.login.success'),
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    router.push(navigateTo && navigateTo !== '/login' ? navigateTo : '/')
  }

  return {
    signIn,
    loginValidation,
    userEmail,
    password,
    loading,
  }
}
