export function OpenLoginMenu() {
  document.getElementById('DivLogin').classList.add('Show_DivLogin')
}

export function CloseLoginMenu() {
  document.getElementById('DivLogin').classList.remove('Show_DivLogin')
  document.getElementById('LoginTitle').classList.remove('Hide')
  document.getElementById('LoginBody').classList.remove('Hide')
  document.getElementById('LoginFoot').classList.remove('Hide')
  document.getElementById('ForgotPasswordTitle').classList.add('Hide')
  document.getElementById('ForgotPasswordBody').classList.add('Hide')
  document.getElementById('ForgotPasswordFoot').classList.add('Hide')
}

export function OpenForgotPassword() {
  document.getElementById('LoginTitle').classList.add('Hide')
  document.getElementById('LoginBody').classList.add('Hide')
  document.getElementById('LoginFoot').classList.add('Hide')
  document.getElementById('ForgotPasswordTitle').classList.remove('Hide')
  document.getElementById('ForgotPasswordBody').classList.remove('Hide')
  document.getElementById('ForgotPasswordFoot').classList.remove('Hide')
}

export function CloseForgotPassword() {
  document.getElementById('LoginTitle').classList.remove('Hide')
  document.getElementById('LoginBody').classList.remove('Hide')
  document.getElementById('LoginFoot').classList.remove('Hide')
  document.getElementById('ForgotPasswordTitle').classList.add('Hide')
  document.getElementById('ForgotPasswordBody').classList.add('Hide')
  document.getElementById('ForgotPasswordFoot').classList.add('Hide')
}
