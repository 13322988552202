export function ScrollScript(){
	try{
		if(document.documentElement.scrollTop > 720){
			document.getElementById("Porta_Btn_OpenMenu").classList.add("Show_Porta_Btn_OpenMenu");
			document.getElementById("BackOnTop").classList.add("BackOnTop_Sobe");
			//document.getElementById("localeDropdown").classList.add("d-sm-none");
		}else{
			document.getElementById("Porta_Btn_OpenMenu").classList.remove("Show_Porta_Btn_OpenMenu");
			document.getElementById("BackOnTop").classList.remove("BackOnTop_Sobe");
			document.getElementById("Porta_Btn_OpenMenu").classList.remove("Desce_Porta_Btn_Menu");
			document.getElementById("Btn_OpenMenu").classList.remove("Hide_Btn_OpenMenu");
			document.getElementById("Btn_CloseMenu").classList.add("Hide_Btn_CloseMenu");
			document.getElementById("Porta_Menu").classList.remove("Show_Porta_Menu_Small");
			document.getElementById("Porta_Menu").classList.remove("Cor_Porta_Menu_Small");
			document.getElementById("Porta_Menu").classList.remove('Porta_Menu_Small');
			//document.getElementById("localeDropdown").classList.remove("d-sm-none");
		}
	}
	catch{

	}
	
}