<template>
  <div>
    <b-form>
      <b-row class="justify-content-md-center">
        <b-col cols="8" class="r_center">
          <b-form-group id="input-group-1" :label="$t('contact.componentField1')" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="form.name"
              :placeholder="$t('contact.componentPlaceholder1')"
              required
            ></b-form-input>
          </b-form-group>

          <b-row>
            <b-col>
              <b-form-group
                id="input-group-2"
                :label="$t('contact.componentField2')"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.fromEmail"
                  :placeholder="$t('contact.componentPlaceholder2')"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-3"
                :label="$t('contact.componentField3')"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.phone"
                  :placeholder="$t('contact.componentPlaceholder3')"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group id="input-group-4" :label="$t('contact.componentField4')" label-for="input-4">
            <b-form-input
              id="input-4"
              v-model="form.company"
              :placeholder="$t('contact.componentPlaceholder4')"
              required
            ></b-form-input>
          </b-form-group>
          <br />
          <b-row>
            <b-col cols="8">
              <b-form-group
                id="input-group-5"
                :label="$t('contact.componentField5')"
                label-for="input-5"
              >
                <b-form-input
                  id="input-5"
                  v-model="form.subject"
                  :placeholder="$t('contact.componentPlaceholder5')"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-6"
                :label="$t('contact.componentField6')"
                label-for="input-6"
              >
                <b-form-select
                  id="input-6"
                  v-model="form.plan"
                  :options="options"
                  required
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
            id="input-group-7"
            :label="$t('contact.componentField7')"
            label-for="input-7"
          >
            <b-form-textarea
              id="input-7"
              v-model="form.message"
              :placeholder="$t('contact.componentPlaceholder7')"
              required
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button variant="primary" size="lg" @click="sendMessage()"
        >{{ $t('contact.componentFeature') }}</b-button
      >
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BButton,
  BCol,
  BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import { ContactController } from '@/controllers/contact.controller'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BCol,
    BRow,
  },
  data() {
    return {
      form: {
        name: '',
        fromEmail: '',
        phone: '',
        company: '',
        subject: '',
        plan: null,
        message: '',
      },
      options: [
        { text: 'Select One', value: null },
        'Laboratory',
        'Optic',
        'Software Partner',
      ],
      show: true,
    }
  },
  methods: {
    async sendMessage() {
      const c = new ContactController()
      this.form.toEmail = 'roman@opticanet.com.br'

      c.sendContactFormEmail(this.form)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toast.contact.success'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch((e) => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toast.contact.failure'),
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style></style>
