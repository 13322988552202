<template>
  <div class="col-12 col-md-10 offset-md-1 top40">
    <div v-if="$store.state.plan && plans && plans[0]" class="plans-grid">
      <div v-for="price in sortPrices(plans[0].prices)" :key="price.id">
        <div v-if="price.active">
          <b-card align="center" class="mb-1" style="width: 300px">
            <b-card-title bold class="card-title m-0">{{
              price.metadata.pricingName
            }}</b-card-title>

            <h2 class="plan-price top20 text-secondary">
              <span class="unit">{{ getCurrency(price.currency) }}</span
              >{{ formatPrice(price.unit_amount)
              }}<span class="month">{{
                $t('public.planes.sectionFeatures1')
              }}</span>
            </h2>

            <b-list-group flush class="list-group-circle top20">
              <b-list-group-item class="text-left">
                {{ price.metadata.allowedMeasurements }}
                {{ $t('admin.plansCard.measurements') }}
              </b-list-group-item>

              <b-list-group-item class="text-left">
                {{ price.metadata.allowedSellers }}
                {{ $t('admin.plansCard.sellers') }}
              </b-list-group-item>

              <b-list-group-item class="text-left">
                {{ $t('admin.plansCard.bonus') }}
              </b-list-group-item>

              <b-list-group-item class="text-left">
                {{ $t('admin.plansCard.trial') }}
              </b-list-group-item>

              <b-list-group-item
                class="text-left"
                v-if="price.metadata.discount != null"
              >
                {{ price.metadata.discount
                }}{{ $t('admin.plansCard.discount') }}
              </b-list-group-item>
            </b-list-group>

            <b-button class="top20" @click="contractPlan(plans[0], price.id)">{{
              $t('public.planes.sectionFeatures2')
            }}</b-button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BCardText,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import store from '@/store'
import router from '@/router'
import planStoreModule from '@/views/admin/plans/planStoreModule'
import { computed, onUnmounted } from '@vue/composition-api'

export default {
  name: 'PlansCard',

  props: ['planType'],

  components: {
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
  },

  methods: {
    sortPrices(prices) {
      const arr = [...prices]
      arr.sort((p1, p2) => p1.unit_amount - p2.unit_amount)
      return arr
    },
  },

  mounted() {
    const PLAN_APP_STORE_MODULE_NAME = 'plan'
    if (!this.$store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      this.$store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }
  },

  setup(props) {
    const PLAN_APP_STORE_MODULE_NAME = 'plan'
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME),
    )

    store.dispatch('plan/fetchPlans')

    const plan = props.planType || 'optic'

    const plans = computed({
      get: () =>
        store.state.plan.plans.filter(
          (p) => p.metadata.type && p.metadata.type === plan,
        ),
    })

    const formatPrice = (price) => {
      const result = price / 100
      const isDecimal = result.toString().includes('.')

      return (isDecimal ? result.toFixed(2) : result.toString()).replace(
        '.',
        ',',
      )
    }

    const getCurrency = (currency) => {
      switch (currency) {
        case 'brl':
          return 'R$'
        case 'usd':
          return '$'
        case 'eur':
          return '€'
        default:
          return '$'
      }
    }

    const contractPlan = (plan, priceId) => {
      router.push(`/sign-up?plid=${plan.id}&prid=${priceId}`)
    }

    return {
      plans,
      formatPrice,
      getCurrency,
      contractPlan,
    }
  },
}
</script>

<style scoped>
.card-body {
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-content: space-around;
  flex-wrap: wrap;
}
.card-title {
  font-weight: bold !important;
  font-size: 30px !important;
}

.plans-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  justify-content: center;
}

h2.plan-price {
  font-size: 5rem !important;
}

h2.plan-price span {
  font-size: 2rem !important;
  line-height: 2rem !important;
}

h2.plan-price span.unit {
  vertical-align: super !important;
}

h2.plan-price span.month {
  font-weight: normal !important;
  color: #999;
}

.dark-layout h2.plan-price span.month {
  color: #aaa;
}

h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 55px;
  line-height: 55px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
</style>
