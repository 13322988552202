import { callHttpsCallableFunction } from '../api/firebase'

import { cF } from '../utils/cloud-functions'

/**
 * @typedef {Object} Contact
 * @property {string} name
 * @property {string} fromEmail
 * @property {string} phone
 * @property {string} company
 * @property {string} subject
 * @property {string} plan
 * @property {string} message
 */

/**
 * @typedef {Object} SignUpRequest
 * @property {string} name
 * @property {string} fromEmail
 * @property {string} toEmail
 * @property {string} subject
 */

/**
 * Class responsible for keeping all contact request logic.
 */
export class ContactController {
  /**
   * Sends a contact form email according to the given data.
   *
   * @param {Contact} data an object that contains the contact data.
   */
  async sendContactFormEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data,
      template: 'contact-form',
    })
  }

  /**
   * Sends a sign up request email according to the given data.
   *
   * @param {SignUpRequest} data an object that contains the sign up data.
   */
  async sendSignUpRequestEmail(data) {
    return callHttpsCallableFunction(cF.sendEmail, {
      data,
      template: 'signup-request',
    })
  }
}
