<template>
  <!-- 300 -->
  <div>
    <header>
      <div class="Header_Menu">
        <div
          id="Porta_Btn_OpenMenu"
          class="Porta_Btn_OpenMenu d-flex align-items-center justify-content-center"
        >
          <button
            type="button"
            @click="OpenMenu()"
            id="Btn_OpenMenu"
            class="Btn_OpenMenu"
          >
            <img src="./svg/Menu_Open.svg" class="Icone_Svg_Menu" alt="Menu" />
          </button>

          <button
            type="button"
            @click="CloseMenu()"
            id="Btn_CloseMenu"
            class="Btn_CloseMenu Hide_Btn_CloseMenu"
          >
            <img
              src="./svg/Menu_Close.svg"
              class="Icone_Svg_Close"
              alt="CloseMenu"
            />
          </button>
        </div>

        <div class="Porta_Btn_OpenMenu_Mobile">
          <button type="button" class="Btn_OpenMenu_Mobile">
            <img src="./svg/Menu_Open.svg" class="Icone_Svg_Menu" alt="Menu" />
          </button>

          <button type="button" class="Btn_CloseMenu_Mobile Hide_Btn_CloseMenu">
            <img
              src="./svg/Menu_Close.svg"
              class="Icone_Svg_Close"
              alt="CloseMenu"
            />
          </button>
        </div>

        <div class="Porta_Menu" id="Porta_Menu">
          <div class="container-fluid">
            <div
              class="PortaLogo AlturaMenu d-flex align-items-center justify-content-start"
            >
              <a class="VoltarNavegacao d-none d-sm-block" href="/">
                <img
                  src="./svg/LensExpert___Logo.svg"
                  class="Logo_HeaderMenu Logo_Color"
                  alt="LensXpert"
                />
                <img
                  src="./svg/LensExpert___Logo.svg"
                  class="Logo_HeaderMenu Logo_White Hide_Logo_White"
                  alt="LensXpert"
                />
              </a>

              <a class="btn-scroll d-block d-sm-none" href="javascript:void(0)">
                <img
                  src="./svg/LensXpert___LogoMobile.svg"
                  class="Logo_HeaderMenu"
                  alt="LensXpert"
                />
              </a>
            </div>

            <div class="Dropdown">
              <div class="PortaLinks">
                <ul>
                  <li>
                    <a class="LinkMenu" href="/">{{
                      $t('public.headMenu.home')
                    }}</a>
                  </li>
                  <li>
                    <a
                      class="LinkMenu"
                      href="/tomador-de-medidas"
                      target="_self"
                      >{{ $t('public.headMenu.measurement') }}<br />{{
                        $t('public.headMenu.taker')
                      }}</a
                    >
                  </li>
                  <li>
                    <a class="LinkMenu" href="/consultor-optico" target="_self"
                      >{{ $t('public.headMenu.optical') }}<br />{{
                        $t('public.headMenu.consultant')
                      }}</a
                    >
                  </li>
                  <li>
                    <a class="LinkMenu" href="/tabela-de-lentes" target="_self"
                      >{{ $t('public.headMenu.lens') }}<br />{{
                        $t('public.headMenu.table')
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="LinkMenu LinkGo BtnScrollMais200"
                      href="#Planos"
                      >{{ $t('public.headMenu.plans') }}</a
                    >
                  </li>
                  <li class="BtnOnlyDesktop LiMenor">
                    <a href="#FaleConosco" class="BtnScroll">
                      <img
                        src="./svg/Menu_FaleConosco.svg"
                        class="IconeEmail"
                        alt=""
                        title="FALE CONOSCO"
                      />
                    </a>
                  </li>
                  <div class="LiMenor"><Locale :isFromPublic="true" /></div>
                  <li class="LiMenor" v-if="user" style="cursor: pointer">
                    <div
                      link-class="d-flex align-items-center"
                      @click="$router.push('/admin')"
                    >
                      <feather-icon
                        size="16"
                        color="white"
                        icon="UserIcon"
                        class="mr-50"
                      />
                    </div>
                  </li>
                  <li class="LiMenor" v-if="user" style="cursor: pointer">
                    <div
                      link-class="d-flex align-items-center"
                      @click="logout()"
                    >
                      <feather-icon
                        size="16"
                        color="white"
                        icon="LogOutIcon"
                        class="mr-50"
                      />
                    </div>
                  </li>
                  <li class="LiMenor" v-if="!user">
                    <SignIn />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div id="Home" class="OverlayMenu"></div>
    <div class="PorqueVerde_TabelaOnline d-none d-sm-flex">
      <a
        @click="PorqueVerdeButton()"
        class="Pergunta"
        id="PorqueVerdeTabelaOnline"
        v-html="$t('tabela.sectionFeature3')"
      >
      </a>
      <div class="Resposta" id="Resposta">
        <div class="TextoResposta" id="TextoResposta">
          <p class="SmallTxt" v-html="$t('tabela.sectionDescription')"></p>
        </div>
      </div>
    </div>

    <section class="HeaderInterno">
      <div class="container-fluid NoPadding">
        <img
          src="./images/TabelaOnline_Header_Internas.png"
          class="img-fluid d-none d-sm-block"
          alt="Lensxpert"
        />
        <img
          src="./images/TabelaOnline_Header_Internas_Mobile.png"
          class="img-fluid d-block d-sm-none"
          alt="Lensxpert"
        />
      </div>
    </section>

    <section class="SectionConteudoInternas" id="Apresentacao">
      <div class="container">
        <div class="col-12 col-md-8 offset-md-2">
          <h4 class="LabelBlue" v-html="$t('tabela.sectionLensxpert')"></h4>
          <h3 class="AzulClaro1 top10" v-html="$t('tabela.sectionPrecos')"></h3>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top20 TextoResponsivo">
          <p class="Black">
            A
            <strong
              class="AzulClaro1"
              v-html="$t('tabela.sectionDev1')"
            ></strong>
            {{ $t('tabela.sectionDev11') }}
          </p>

          <p class="Black top10" v-html="$t('tabela.sectionConsult')"></p>

          <p class="Black top10" v-html="$t('tabela.sectionDescription11')"></p>

          <p class="Black top10" v-html="$t('tabela.sectionFeature2')"></p>

          <h4 class="AzulClaro1 top40">VÁRIOS NÍVEIS<br />DE ACESSO:</h4>

          <p class="Black top10" v-html="$t('tabela.sectionDescription31')"></p>

          <p
            class="Black top30 FeaturesConsultor"
            v-html="$t('tabela.sectionFeature1')"
          ></p>
          <p
            class="Black top5 FeaturesConsultor"
            v-html="$t('tabela.sectionFeature9')"
          ></p>
          <p
            class="Black top5 FeaturesConsultor"
            v-html="$t('tabela.sectionFeature10')"
          ></p>
          <p
            class="Black top5 FeaturesConsultor"
            v-html="$t('tabela.sectionFeature11')"
          ></p>
        </div>

        <div class="col-12 text-center top100">
          <img src="./images/1x150pixels.png" alt="Lensxpert" />
        </div>

        <div class="col-12 col-md-8 offset-md-2 top60">
          <h3 class="AzulClaro1" v-html="$t('tabela.sectionDescription3')"></h3>

          <h4
            class="LabelBlue top10"
            v-html="$t('tabela.sectionFeature12')"
          ></h4>
          <img
            src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_Acesso.png"
            width="1000"
          />
        </div>

        <div class="col-12 col-md-10 offset-md-1 top30">
          <!--Imagens-->
          <div class="row">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_Acesso.png"
                href="./images/TabelaOnline/TabelaOnline_Acesso.png"
              >
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature13')"></h4>
              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription13')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_Feed.png"
                href="./images/TabelaOnline/TabelaOnline_Feed.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_Feed.png"
                  width="1000"
                />
              </a>
            </div>
            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature14')"></h4>
              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription14')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_ClubePremios.png"
                href="./images/TabelaOnline/TabelaOnline_ClubePremios.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_ClubePremios.png"
                  width="1000"
                />
              </a>
            </div>
            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4
                class="LabelBlue"
                v-html="$t('tabela.sectionFeaturePremios')"
              ></h4>
              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionFeaturePremios')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_Armacoes.png"
                href="./images/TabelaOnline/TabelaOnline_Armacoes.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_Armacoes.png"
                  width="1000"
                />
              </a>
            </div>
            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4
                class="LabelBlue"
                v-html="$t('tabela.sectionFeatureArmacao')"
              ></h4>
              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionFeatureArmacao1')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_1_TipoDeLente.png"
                href="./images/TabelaOnline/TabelaOnline_1_TipoDeLente.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_1_TipoDeLente.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature15')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription15')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_1_Pesquisa.png"
                href="./images/TabelaOnline/TabelaOnline_1_Pesquisa.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_1_Pesquisa.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature16')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription16')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_2_ValorDaLente.png"
                href="./images/TabelaOnline/TabelaOnline_2_ValorDaLente.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_2_ValorDaLente.png "
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature17')"></h4>
              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription17')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_3_Dioptria.png"
                href="./images/TabelaOnline/TabelaOnline_3_Dioptria.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_3_Dioptria.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature18')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription18')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_3_CalcularDioptria.png"
                href="./images/TabelaOnline/TabelaOnline_3_CalcularDioptria.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_3_CalcularDioptria.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature19')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription19')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_4_Refracao.png"
                href="./images/TabelaOnline/TabelaOnline_4_Refracao.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_4_Refracao.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature33')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionFeature34')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_5_Material.png"
                href="./images/TabelaOnline/TabelaOnline_5_Material.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_5_Material.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature35')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionFeature36')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_6_Tratamento.png"
                href="./images/TabelaOnline/TabelaOnline_6_Tratamento.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_6_Tratamento.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature20')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription20')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_7_Marca.png"
                href="./images/TabelaOnline/TabelaOnline_7_Marca.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_7_Marca.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature21')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription21')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_8_Familia.png"
                href="./images/TabelaOnline/TabelaOnline_8_Familia.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_8_Familia.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature22')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription22')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_9_Valor.png"
                href="./images/TabelaOnline/TabelaOnline_9_Valor.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_9_Valor.png"
                  width="1000"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature23')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription23')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <!-- <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_10_Sugestoes.png"
                href="./images/TabelaOnline/TabelaOnline_10_Sugestoes.png"
              >
                <img src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_10_Sugestoes.png" width="1000"
                />
              </a> -->
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature24')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription24')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_11_SalvarConsulta.png"
                href="./images/TabelaOnline/TabelaOnline_11_SalvarConsulta.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_11_SalvarConsulta.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature25')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription25')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_12_EnviarWhatsApp.png"
                href="./images/TabelaOnline/TabelaOnline_12_EnviarWhatsApp.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_12_EnviarWhatsApp.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature26')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription26')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_13_ConsultasSalvas.png"
                href="./images/TabelaOnline/TabelaOnline_13_ConsultasSalvas.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_13_ConsultasSalvas.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature27')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription27')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_14_ConsultaSalva.png"
                href="./images/TabelaOnline/TabelaOnline_14_ConsultaSalva.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_14_ConsultaSalva.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature37')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription36')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_15_Convertendo.png"
                href="./images/TabelaOnline/TabelaOnline_15_Convertendo.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_15_Convertendo.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature28')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription28')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_15_Dashboard.png"
                href="./images/TabelaOnline/TabelaOnline_15_Dashboard.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_15_Dashboard.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature29')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription29')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_15_Dashboard_2.png"
                href="./images/TabelaOnline/TabelaOnline_15_Dashboard_2.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_15_Dashboard_2.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature29')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription30')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_15_Dashboard_3.png"
                href="./images/TabelaOnline/TabelaOnline_15_Dashboard_3.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_15_Dashboard_3.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature29')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription29')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_15_Dashboard_4.png"
                href="./images/TabelaOnline/TabelaOnline_15_Dashboard_4.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_15_Dashboard_4.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature29')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription37')"
              ></p>
            </div>
          </div>
          <!--Imagens-->

          <!--Imagens-->
          <div class="row top60">
            <div class="col-12">
              <a
                data-fancybox="gallery1"
                data-srcset="./images/TabelaOnline/TabelaOnline_15_Dashboard_5.png"
                href="./images/TabelaOnline/TabelaOnline_15_Dashboard_5.png"
              >
                <img
                  src="https://dev.lensxpert.com.br/static/public/site/images/TabelaOnline/TabelaOnline_15_Dashboard_5.png"
                  width="1000"
                  class="img-fluid"
                  alt="Lensxpert"
                />
              </a>
            </div>

            <div class="col-12 col-md-8 offset-md-2 top30">
              <h4 class="LabelBlue" v-html="$t('tabela.sectionFeature29')"></h4>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription32')"
              ></p>
            </div>
          </div>
          <!--Imagens-->
        </div>

        <div class="col-12 col-md-8 offset-md-2 top60">
          <h3 class="AzulClaro1" v-html="$t('tabela.sectionFeature100')"></h3>

          <h4
            class="LabelBlue top10"
            v-html="$t('tabela.sectionFeature30')"
          ></h4>

          <p
            class="Black top30 TextoResponsivo"
            v-html="$t('tabela.sectionDescription33')"
          ></p>
        </div>

        <div class="col-12 NoPadding top60">
          <div
            class="owl-carousel owl-theme Carousel_TabelaOnline"
            id="Carousel_TabelaOnline"
          >
            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="LOGIN LOJISTA"
                    data-srcset="./images/TabelaOnline/Lojista/1_Login.png"
                    href="./images/TabelaOnline/Lojista/1_Login.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/1_Login.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature31')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription34')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="MENU PRINCIPAL"
                    data-srcset="./images/TabelaOnline/Lojista/2_Menu.png"
                    href="./images/TabelaOnline/Lojista/2_Menu.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/2_Menu.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1 text-center">
                    <strong v-html="$t('tabela.sectionFeature32')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription35')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="DADOS DA LOJA"
                    data-srcset="./images/TabelaOnline/Lojista/3_DadosLoja.png"
                    href="./images/TabelaOnline/Lojista/3_DadosLoja.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/3_DadosLoja.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature38')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription38')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="ATENDENTES"
                    data-srcset="./images/TabelaOnline/Lojista/4_Atendentes.png"
                    href="./images/TabelaOnline/Lojista/4_Atendentes.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/4_Atendentes.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature39')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription39')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="DADOS E DASHBOARD DO ATENDENTE"
                    data-srcset="./images/TabelaOnline/Lojista/5_DadosAtendente.png"
                    href="./images/TabelaOnline/Lojista/5_DadosAtendente.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/5_DadosAtendente.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature40')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription40')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="DASHBOARD DO ATENDENTE"
                    data-srcset="./images/TabelaOnline/Lojista/6_DashboardAtendentes_1.png"
                    href="./images/TabelaOnline/Lojista/6_DashboardAtendentes_1.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/6_DashboardAtendentes_1.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature41')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription41')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DO ATENDENTE"
                    data-srcset="./images/TabelaOnline/Lojista/7_DashboardAtendentes_2.png"
                    href="./images/TabelaOnline/Lojista/7_DashboardAtendentes_2.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/7_DashboardAtendentes_2.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature42')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription42')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DO ATENDENTE"
                    data-srcset="./images/TabelaOnline/Lojista/7_DashboardAtendentes_3.png"
                    href="./images/TabelaOnline/Lojista/7_DashboardAtendentes_3.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/7_DashboardAtendentes_3.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature43')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription43')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DO ATENDENTE"
                    data-srcset="./images/TabelaOnline/Lojista/7_DashboardAtendentes_4.png"
                    href="./images/TabelaOnline/Lojista/7_DashboardAtendentes_4.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/7_DashboardAtendentes_4.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature43')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription44')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DO ATENDENTE"
                    data-srcset="./images/TabelaOnline/Lojista/7_DashboardAtendentes_5.png"
                    href="./images/TabelaOnline/Lojista/7_DashboardAtendentes_5.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/7_DashboardAtendentes_5.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature43')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription45')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DO ATENDENTE"
                    data-srcset="./images/TabelaOnline/Lojista/7_DashboardAtendentes_6.png"
                    href="./images/TabelaOnline/Lojista/7_DashboardAtendentes_6.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/7_DashboardAtendentes_6.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature43')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription46')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="TABELAS DE PREÇOS"
                    data-srcset="./images/TabelaOnline/Lojista/8_Precos.png"
                    href="./images/TabelaOnline/Lojista/8_Precos.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/8_Precos.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature44')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription47')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="DASHBOARD DA LOJA"
                    data-srcset="./images/TabelaOnline/Lojista/9_DashboardLoja_1.png"
                    href="./images/TabelaOnline/Lojista/9_DashboardLoja_1.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/9_DashboardLoja_1.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature45')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription50')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DA LOJA"
                    data-srcset="./images/TabelaOnline/Lojista/10_DashboardLoja_2.png"
                    href="./images/TabelaOnline/Lojista/10_DashboardLoja_2.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/10_DashboardLoja_2.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature46')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription51')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DA LOJA"
                    data-srcset="./images/TabelaOnline/Lojista/11_DashboardLoja_3.png"
                    href="./images/TabelaOnline/Lojista/11_DashboardLoja_3.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/11_DashboardLoja_3.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature46')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription52')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DA LOJA"
                    data-srcset="./images/TabelaOnline/Lojista/12_DashboardLoja_4.png"
                    href="./images/TabelaOnline/Lojista/12_DashboardLoja_4.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/12_DashboardLoja_4.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature46')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription52')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DA LOJA"
                    data-srcset="./images/TabelaOnline/Lojista/12_DashboardLoja_5.png"
                    href="./images/TabelaOnline/Lojista/12_DashboardLoja_5.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/12_DashboardLoja_5.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature46')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription53')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery200"
                    data-caption="NÚMEROS DA LOJA"
                    data-srcset="./images/TabelaOnline/Lojista/12_DashboardLoja_6.png"
                    href="./images/TabelaOnline/Lojista/12_DashboardLoja_6.png"
                  >
                    <img
                      src="./images/TabelaOnline/Lojista/12_DashboardLoja_6.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature46')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription48')"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 text-center top100">
          <img src="./images/1x150pixels.png" alt="Lensxpert" />
        </div>

        <div class="col-12 col-md-8 offset-md-2 top60">
          <h3
            class="AzulClaro1"
            v-html="$t('tabela.sectionDescription55')"
          ></h3>

          <h4
            class="LabelBlue top10"
            v-html="$t('tabela.sectionFeature47')"
          ></h4>

          <p class="Black top30" v-html="$t('tabela.sectionDescription56')"></p>
        </div>

        <div class="col-12 NoPadding top60">
          <div
            class="owl-carousel owl-theme Carousel_TabelaOnline"
            id="Carousel_TabelaOnlineAdmin"
          >
            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="LOGIN ADMIN"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_1.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_1.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_1.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature48')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription57')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="MENU DO ADMIN"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_2.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_2.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_2.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature49')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription58')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_3.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_3.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_3.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature50')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription59')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_4.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_4.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_4.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature50')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription60')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_5.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_5.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_5.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature50')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription61')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_6.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_6.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_6.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature50')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription62')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_7.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_7.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_7.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature50')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription63')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_8.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_8.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_8.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature50')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription64')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_9.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_9.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_9.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature50')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription65')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_10.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_10.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_10.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature50')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription66')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="LOJAS"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_11.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_11.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_11.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature51')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription67')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="LOJA"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_12.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_12.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_12.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature51')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription68')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD DA LOJA"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_13.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_13.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_13.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature52')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription69')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ATENDENTES DA LOJA"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_14.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_14.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_14.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature53')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription70')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ATENDENTE DA LOJA"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_15.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_15.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_15.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature54')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription71')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="DASHBOARD DO ATENDENTE"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_16.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_16.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_16.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature55')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription72')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="PRODUTOS"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_17.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_17.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_17.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature56')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription73')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="PREÇOS"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_18.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_18.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_18.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature57')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription74')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ATENDENTES"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_19.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_19.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_19.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature54')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription75')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="FEEDS"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_20.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_20.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_20.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature58')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription76')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ADICIONAR FEED"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_21.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_21.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_21.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature59')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription77')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ADICIONAR FEED"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_22.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_22.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_22.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature60')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription78')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ADICIONAR FEED"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_23.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_23.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_23.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature61')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription79')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ADICIONAR FEED"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_24.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_24.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_24.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature62')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription80')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ADICIONAR FEED"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_25.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_25.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_25.png"
                      class="img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature63')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription81')"
                  ></p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-12 col-md-7">
                  <a
                    data-fancybox="gallery300"
                    data-caption="ADICIONAR FEED"
                    data-srcset="./images/TabelaOnline/Admin/TabelaOnline_Admin_26.png"
                    href="./images/TabelaOnline/Admin/TabelaOnline_Admin_26.png"
                  >
                    <img
                      src="./images/TabelaOnline/Admin/TabelaOnline_Admin_26.png"
                      class="lazy img-fluid"
                      alt="Lensxpert"
                    />
                  </a>
                </div>

                <div class="col-12 col-md-5 BoxTextoTabela">
                  <p class="AzulClaro1">
                    <strong v-html="$t('tabela.sectionFeature64')"></strong>
                  </p>

                  <p
                    class="MediumTxt Black top10"
                    v-html="$t('tabela.sectionDescription82')"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 text-center top100">
          <img src="./images/1x150pixels.png" alt="Lensxpert" />
        </div>

        <div class="col-12 col-md-10 offset-md-1" id="Vantagens">
          <div class="row Features">
            <div class="col-12 col-md-4">
              <img
                src="./svg/MultiAcessos.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature65')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription83')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Ecommerce.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature66')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription84')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Precisao.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature67')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription85')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Armazenados.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature68')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription86')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Impressao.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature69')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription89')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Lentes.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature70')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription90')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/AtendimentoDiferenciado.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature71')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription91')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/FacilAcessar.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature72')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription92')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/SeuDominio.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature73')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription93')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/InvestimentoAcessivel.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature74')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription94')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/MaiorLucratividade.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature75')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription95')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/MultiPlataforma.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature76')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription96')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/CrossBrowser.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature77')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription97')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Seguro.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature78')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription98')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/OnlineModerno.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature79')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription99')"
              ></p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top80">
          <p
            class="Black SmallTxt"
            v-html="$t('tabela.sectionDescription100')"
          ></p>
        </div>
      </div>
    </section>

    <planes></planes>

    <section class="FaleConosco FaleConoscoHome" id="FaleConosco">
      <div class="container ContainerFaleConosco">
        <div class="col-12 col-md-8 offset-md-2 top100">
          <img
            src="./svg/FaleConosco.svg"
            class="IconeFaleConosco"
            alt="LensXpert"
          />

          <h5
            class="AzulClaro1"
            v-html="$t('public.home.sectionDescription1')"
          ></h5>

          <p
            class="MediumTxt Black top10"
            v-html="$t('public.home.sectionDescription2')"
          ></p>
        </div>
        <br /><br />

        <Contact />
      </div>
    </section>
    <footer>
      <div class="container">
        <div class="col-12">
          <div class="row HeightFooter">
            <img
              src="./svg/LensExpert___Logo.svg"
              class="Logo"
              alt="LensXpert"
            />
            <p
              class="SmallTxt White top20 text-center"
              v-html="$t('tabela.sectionDescription3')"
            ></p>
          </div>
        </div>
      </div>
    </footer>
    <a
      id="BackOnTop"
      href="#Home"
      class="BackOnTop d-flex align-items-center justify-content-center"
    >
      <img src="./svg/BackTop.svg" class="BackIcone" alt="LensXpert" />
    </a>
  </div>
</template>

<script>
import { PorqueVerdeButton } from './js/PorqueButton.js'
import { ScrollScript } from './js/ScrollScript.js'
import { OpenMenu, CloseMenu } from './js/ScrollMenuButton.js'

import Planes from '@/views/public/sections/Plans.vue'
import SignIn from '@/components/SignIn.vue'
import Contact from '@/components/Contact.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    Planes,
    SignIn,
    Contact,
    Locale,
  },
  created() {
    window.addEventListener('scroll', ScrollScript)
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    PorqueVerdeButton,
    ScrollScript,
    OpenMenu,
    CloseMenu,

    async logout() {
      await this.$store.dispatch('auth/logout')
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'css/animate.css';
@import 'css/bootstrap.min.css';
@import 'css/cubeportfolio.min.css';
@import 'css/font-awesome.min.css';
@import 'css/jquery.fancybox.css';
@import 'css/MyColors.css';
@import 'css/MyForm.css';
@import 'css/MyNav.css';
@import 'css/MyResponsive.css';
@import 'css/MyStyle.css';
@import 'css/style.css';

html {
  scroll-behavior: smooth;
}

.plans-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  justify-content: center;
}

h2.plan-price {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 5rem;
  line-height: 55px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

h2.plan-price span {
  font-size: 2rem;
  line-height: 2rem;
}

h2.plan-price span.unit {
  vertical-align: super;
}

h2.plan-price span.month {
  font-weight: normal;
  color: #999;
}

.dark-layout h2.plan-price span.month {
  color: #aaa;
}

footer {
  width: 100%;
  height: 300px;
  background: #0c8cb9 url('../../views/public/images/Footer_HeaderHome.png') top
    center no-repeat;
  background-size: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

footer .HeightFooter {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .Logo {
  width: auto;
  height: 85px;
  margin: 0 0 0 0;
}

footer .Assinatura {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  margin: 0 auto;
}

footer .SmallTxt {
  letter-spacing: 2px;
}
</style>
