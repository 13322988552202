<template>
  <section class="Planos" id="Planos">
    <div class="container">
      <div class="col-12 col-md-8 offset-md-2 r_100">
        <img src="../svg/Planos.svg" class="IconePlanos" alt="LensXpert" />
        <h5 class="AzulClaro1" v-html="$t('public.planes.sectionTitle')"></h5>
        <p
          class="MediumTxt Black top10"
          v-html="$t('public.planes.sectionDescription')"
        ></p>
      </div>
      <PlansCard />
    </div>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BCardText,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import store from '@/store'
import router from '@/router'
import planStoreModule from '@/views/admin/plans/planStoreModule'
import { computed, onUnmounted } from '@vue/composition-api'
import PlansCard from '@/components/PlansCard.vue'

export default {
  components: {
    PlansCard,
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
  },

  mounted() {
    const PLAN_APP_STORE_MODULE_NAME = 'plan'
    if (!this.$store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      this.$store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }
  },

  setup() {
    const PLAN_APP_STORE_MODULE_NAME = 'plan'
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME),
    )

    store.dispatch('plan/fetchPlans')

    const plans = computed({
      get: () =>
        store.state.plan.plans.filter(
          (p) => p.metadata && p.metadata.type === 'optic',
        ),
    })

    const formatPrice = (price) => {
      const result = price / 100
      const isDecimal = result.toString().includes('.')

      return (isDecimal ? result.toFixed(2) : result.toString()).replace(
        '.',
        ',',
      )
    }

    const getCurrency = (currency) => {
      switch (currency) {
        case 'brl':
          return 'R$'
        case 'usd':
          return '$'
        case 'eur':
          return '€'
        default:
          return '$'
      }
    }

    const contractPlan = (plan, priceId) => {
      router.push(`/sign-up?plid=${plan.id}&prid=${priceId}`)
    }

    return {
      plans,
      formatPrice,
      getCurrency,
      contractPlan,
    }
  },
}
</script>

<style scoped>
.card-body {
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-content: space-around;
  flex-wrap: wrap;
}
.card-title {
  font-weight: bold !important;
  font-size: 30px !important;
}

.plans-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  justify-content: center;
}

h2.plan-price {
  font-size: 5rem !important;
}

h2.plan-price span {
  font-size: 2rem !important;
  line-height: 2rem !important;
}

h2.plan-price span.unit {
  vertical-align: super !important;
}

h2.plan-price span.month {
  font-weight: normal !important;
  color: #999;
}

.dark-layout h2.plan-price span.month {
  color: #aaa;
}

h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 55px;
  line-height: 55px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
</style>
